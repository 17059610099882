
<template>
  <div class="tinymce-box">
    <editor v-model="myValue" :init="init" :disabled="disabled" @onClick="onClick">
    </editor>
  </div>
</template>

<script>
import tinymce from "@/utils/tinymce/tinymce"; //tinymce默认hidden，不引入不显示
import Editor from "@tinymce/tinymce-vue";
import "@/utils/tinymce/themes/silver";

// 编辑器插件plugins
// 更多插件参考：https://www.tiny.cloud/docs/plugins/
// import "tinymce/plugins";
import "@/utils/tinymce/plugins/image"; // 插入上传图片插件
import "@/utils/tinymce/plugins/media"; // 插入视频插件
import "@/utils/tinymce/plugins/table"; // 插入表格插件
import "@/utils/tinymce/plugins/lists"; // 列表插件
import "@/utils/tinymce/plugins/print";
import "@/utils/tinymce/plugins/fullscreen";
import "@/utils/tinymce/plugins/preview";
import "@/utils/tinymce/plugins/link";
import "@/utils/tinymce/plugins/wordcount";
import "@/utils/tinymce/plugins/charmap"; // 字数统计插件
import "@/utils/tinymce/plugins/emoticons";
import "@/utils/tinymce/plugins/insertdatetime";
import "@/utils/tinymce/plugins/hr";
import "@/utils/tinymce/plugins/paste";
import { FileUpload } from "@/api/api";
export default {
  components: {
    Editor,
  },
  name: "tinymce",
  props: {
    value: {
      type: String,
      default: "",
    },
    baseUrl: {
      type: String,
      default: process.env.NODE_ENV === "development" ? "" : "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    plugins: {
      type: [String, Array],
      default:
        "preview   fullscreen image link media    table charmap hr  insertdatetime  lists wordcount    print preview fullscreen emoticons insertdatetime hr  paste ",
    },
    toolbar: {
      type: [String, Array],
      default:
        "undo redo |  formatselect | bold italic forecolor backcolor| fontselect fontsizeselect underline strikethrough link  blockquote  subscript superscript removeformat | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | lists image media table charmap  hr insertdatetime|   removeformat print preview fullscreen  ",
    },
  },
  data () {
    const userInfo = JSON.parse(window.localStorage.getItem('ecolUserInfo'));
    const uid = userInfo ? userInfo.id : null;
    return {
      init: {
        language_url: this.baseUrl + "/tinymce/langs/zh_CN.js",
        language: "zh_CN",
        skin_url: this.baseUrl + "/tinymce/skins/ui/oxide",
        // skin_url: 'tinymce/skins/ui/oxide-dark',//暗色系
        height: 600,
        width: 1201.8,
        plugins: this.plugins,
        toolbar: this.toolbar,
        branding: false,
        invalid_elements: "section",
        media_poster: true,
        media_alt_source: false,
        paste_auto_cleanup_on_paste: true,
        paste_remove_styles: true,
        //上传视频配置
        file_picker_types: "image media file",
        paste_remove_styles_if_webkit: true,
        paste_strip_class_attributes: true,
        emoticons_database_url: this.baseUrl + "/tinymce/emojis.js",
        menubar: false,
        convert_urls: false, //这个参数加上去就可以了
        content_style: "img {max-width:1170px;height:auto} ",
        fontsize_formats: "12px 14px 16px 18px 24px 36px 48px 56px 72px",
        font_formats:
          "微软雅黑=Microsoft YaHei,Helvetica Neue,PingFang SC,sans-serif;苹果苹方=PingFang SC,Microsoft YaHei,sans-serif;宋体=simsun,serif;仿宋体=FangSong,serif;黑体=SimHei,sans-serif;Arial=arial,helvetica,sans-serif;Arial Black=arial black,avant garde;Book Antiqua=book antiqua,palatino;",
        // 此处为图片上传处理函数，这个直接用了base64的图片形式上传图片，
        // 如需ajax上传可参考https://www.tiny.cloud/docs/configure/file-image-upload/#images_upload_handler
        // images_upload_handler: (blobInfo, success, failure) => {
        //   const img = "data:image/jpeg;base64," + blobInfo.base64();
        //   success(img);
        // },
        images_upload_handler: (blobInfo, success, failure) => {

          const form = new FormData();
          form.append("file", blobInfo.blob());
          form.append("path", "/ecol/License/" + uid);
          FileUpload(form).then((res) => {
            if (res && res.code == 200) {
              let data = res.data[0]
              console.log(res, "resresres");
              success(`https://www.njaidc.com/ecol/app/image?path=${data.path}/${data.fileName}`);
            } else {
              failure("上传失败");
            }
          })
        },
        //上传视频
        file_picker_callback: (callback, value, meta) => {
          if (meta.filetype == "media") {
            let input = document.createElement("input"); //创建一个隐藏的input
            input.setAttribute("type", "file");
            input.setAttribute(
              "accept",
              "video/mp4,video/AVI,video/mov,video/FLV,video/rmvb,video/mtv,video/mpeg"
            );
            let _this = this;
            input.onchange = function () {
              let file = this.files[0]; //选取第一个文件
              // console.log(file, "filefile");
              const isLt1024M = file.size / 1024 / 1024 < 200;
              if (!isLt1024M) {
                _this.$message.error({
                  message: "上传视频不能超过200M哦!",
                  duration: 1000,
                  customClass: "zZindex",
                });
                return false;
              }
              const form = new FormData();
              form.append("file", file);
              form.append("path", "/ecol/License/" + uid);
              const messageInstance = _this.$message.warning({
                message: "视频上传中...",
                duration: 0,
                customClass: "zZindex",
              });
              FileUpload(form)
                .then((res) => {
                  if (res && res.code == 200) {
                    let data = res.data[0]
                    callback(`https://www.njaidc.com/ecol/app/image?path=${data.path}/${data.fileName}`);
                  }
                })
                .catch((err) => {
                  that.$message("上传失败");
                }).finally(() => {
                  messageInstance.close();
                })
            };
            //触发点击
            input.click();
          } else if (meta.filetype == "image") {
            //视频封面

            var input = document.createElement("input");
            input.setAttribute("type", "file");
            input.setAttribute(
              "accept",
              "image/gif,image/jpeg,image/png,image/jpg/webp,image/"
            );
            let that = this;
            input.click();
            input.onchange = function (e) {
              let file = this.files[0];
              let ext = file.name.split(".").pop().toLowerCase();
              const allowedExtensions = ["jpg", "jpeg", "png", "gif", "bmp", "png", "webp"];
              if (!allowedExtensions.includes(ext)) {
                this.$message.error({
                  message: "上传资源只能是 jpg/jpeg/png/gif/bmp/png/webp 格式的图片!",
                  duration: 5000,
                  customClass: "zZindex",
                });
                return false;
              }
              const isLt2M = file.size / 1024 / 1024 < 2;
              if (!isLt2M) {
                that.message.error({
                  message: "上传图片大小不能超过 2MB!",
                });
                return false;
              }
              let param = {
                suffix: ext, // 文件后缀  png
                contentType: file.type, // 文件类型  image/png
              };
              // console.log("封面", param);
              const form = new FormData();
              form.append("file", file);
              form.append("path", "/ecol/License/" + uid);
              FileUpload(form)
                .then((res) => {
                  if (res && res.code == 200) {
                    let data = res.data[0]
                    that.coverImage = `https://www.njaidc.com/ecol/app/image?path=${data.path}/${data.fileName}`;
                    callback(`https://www.njaidc.com/ecol/app/image?path=${data.path}/${data.fileName}`);
                  }
                })
                .catch((err) => {
                  that.$message("上传失败");
                });
            };
          } else if (meta.filetype == "file") {
            let input = document.createElement("input"); //创建一个隐藏的input
            input.setAttribute("type", "file");
            input.setAttribute("accept", ".pdf,.ppt,.txt,.xls,.zip,.rar");
            let _this = this;
            input.onchange = function () {
              let file = this.files[0]; //选取第一个文件

              // console.log(file, "filefile");
              const isLt1024M = file.size / 1024 / 1024 < 100;
              if (!isLt1024M) {
                _this.$message.error({
                  message: "上传文件不能超过100M哦!",
                  duration: 50000,
                  customClass: "zZindex",
                });
                return false;
              }
              const form = new FormData();
              form.append("file", file);
              form.append("path", "/ecol/License/" + uid);
              FileUpload(form)
                .then((res) => {
                  if (res && res.code == 200) {
                    let data = res.data[0]
                    console.log(res, "resresres");
                    callback(`https://eap.extrotec.com/ecol/app/image?path=${data.path}/${data.fileName}`);
                  }
                })
                .catch((err) => {
                  that.$message("上传失败");
                });
            };
            //触发点击
            input.click();
          }
        },
        // video_template_callback: (data) => {
        //   return `<video controls="controls" width="${data.width}" poster="${this.coverImage}" height="${data.height}">
        //   <source src="${data.source1}"   type="${data.source1mime}" /></video>`;
        // },

        setup: function (editor) {
          editor.on("init", function (e) {
            this.getBody().style.fontSize = "18px";
            this.getBody().style.color = "#444";
            this.getBody().style.fontFamily = "微软雅黑";
            this.getBody().style.lineHeight = "37px";
            // this.getBody().style.textIndent = "43px";
          });
        },
      },
      myValue: this.value,
      coverImage: "",
    };
  },
  mounted () {
    tinymce.init({});
  },
  methods: {
    // 添加相关的事件，可用的事件参照文档=> https://github.com/tinymce/tinymce-vue => All available events
    // 需要什么事件可以自己增加
    onClick (e) {
      this.$emit("onClick", e, tinymce);
    },
    // 可以添加一些自己的自定义事件，如清空内容
    clear () {
      this.myValue = "";
    },
  },
  watch: {
    value (newValue) {
      this.myValue = newValue;
    },
    myValue (newValue) {
      this.$emit("input", newValue);
    },
  },
};
</script>
<style scoped>
.tinymce-box {
  margin: 0 !important;
}
</style>
